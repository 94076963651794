<template lang="">
   <div class="nk-app-root">
        <!-- main @s -->
        <div class="nk-main ">
            <!-- wrap @s -->
            <div class="nk-wrap nk-wrap-nosidebar">
                <!-- content @s -->
                <div class="nk-content ">
                    <div class="nk-block nk-block-middle wide-xs mx-auto">
                        <div class="nk-block-content nk-error-ld text-center">
                            <h1 class="nk-error-head">403</h1>
                            <h3 class="nk-error-title">Oops! Why you’re here?</h3>
                            <p class="nk-error-text">We are very sorry for inconvenience. You are not authorized to access this page!</p>
                             <router-link class="btn btn-lg btn-primary mt-2" :to="{path: '/login'}" > Back to Login</router-link>
                        </div>
                    </div><!-- .nk-block -->
                </div>
                <!-- wrap @e -->
            </div>
            <!-- content @e -->
        </div>
        <!-- main @e -->
    </div> 
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>